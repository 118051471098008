div {
    margin-bottom: 10px;
}
.pretty-json-container div {
    margin-bottom: 0;
}

.ok {
    color: green;
}

.error {
    color: red;
}

.wrapall {
    word-break: break-all;
}

.bold {
    font-weight: bold;
}